import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Work } from '@templates'
import {
  prismicGatsbyImageSharp,
  prismicCaseStudyCard,
  prismicPortraitCaseStudyCard,
  GatsbyImageSharpFluid_noBase64,
  prismicMailingListFields,
} from '@graphql/fragments'
import { getSeoProps } from '@data/seo'

export const query = graphql`
  query GetWorkPageData {
    prismic {
      allWork_pages {
        edges {
          node {
            seodescription
            seotitle
            heading
            body {
              ... on PRISMIC_Work_pageBody1_case_study_block {
                type
                primary {
                  case_study1 {
                    ...prismicCaseStudyCard
                  }
                }
              }
              ... on PRISMIC_Work_pageBody2_case_study_block {
                type
                label
                primary {
                  left_case_study {
                    ...prismicCaseStudyCard
                  }
                  right_case_study {
                    ...prismicCaseStudyCard
                  }
                }
              }
              ... on PRISMIC_Work_pageBody4_case_study_block {
                type
                primary {
                  bottom_left_case_study {
                    ...prismicCaseStudyCard
                  }
                  bottom_right_case_study {
                    ...prismicPortraitCaseStudyCard
                  }
                  top_left_case_study {
                    ...prismicPortraitCaseStudyCard
                  }
                  top_right_case_study {
                    ...prismicCaseStudyCard
                  }
                }
              }
            }
            mailinglist {
              ...prismicMailingListFields
            }            
          }
        }
      }
    }
  }
`

const WorkPage = ({ data }) => {
  const nestedData = get(data, 'prismic.allWork_pages.edges[0].node')

  if (!nestedData) {
    return null
  }

  const seo = getSeoProps(nestedData)

  return <Work {...{ seo }} {...nestedData} />
}
WorkPage.fragments = [prismicPortraitCaseStudyCard, prismicCaseStudyCard]

export default WorkPage

WorkPage.query = query